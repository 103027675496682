/* You can add global styles to this file, and also import other style files */
// @tailwind base;
// @tailwind components;
// @tailwind utilities;
:root {
  --sbd-brand-green: #56c271;
  --sbd-brand-orange: #fd7f13;
  --sbd-brand-blue: #027398;
  --sbd-lightblue-fill: #EFF3F8;
  --delete-color: #fa384b;
  --white: #fff;
  --black: #000;
}

.success-snackbar {
  .mdc-snackbar__surface {
    --mdc-snackbar-container-color: var(--sbd-brand-green);
    --mdc-snackbar-supporting-text-color: var(--black);
  }
}

.error-snackbar {
  .mdc-snackbar__surface {
    --mdc-snackbar-container-color: var(--delete-color);
    --mdc-snackbar-supporting-text-color: var(--white);
  }
}

.info-snackbar {
  .mdc-snackbar__surface {
    --mdc-snackbar-container-color: var(--sbd-brand-blue);
    --mdc-snackbar-supporting-text-color: var(--white);
  }
}

.warning-snackbar {
  .mdc-snackbar__surface {
    --mdc-snackbar-container-color: var(--sbd-brand-orange);
    --mdc-snackbar-supporting-text-color: var(--white);
  }
}

.selected-row {
  background-color: rgba(var(--fuse-bg-hover-rgb), 0.4) !important;
}

.selected-table-row {
  td {
    position: relative;
    background-color: rgba(var(--fuse-bg-hover-rgb), 0.4) !important;
  }

  td:first-child::after {
    content: ' ';
    background-color: rgba(var(--fuse-primary-rgb), 1);
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
  }
}

.dark {
  .selected-row {
    background-color: rgba(var(--fuse-bg-hover-rgb), 0.025) !important;
  }
  .selected-table-row {
    td {
      position: relative;
      background-color: rgba(var(--fuse-bg-hover-rgb), 0.025) !important;
    }
  }
}

fuse-vertical-navigation
  fuse-vertical-navigation-collapsable-item
  .fuse-vertical-navigation-item-children
  .fuse-vertical-navigation-item {
  color: #fff !important;
}

a.fuse-vertical-navigation-item {
  color: #fff !important;
}

.bg-renewal {
  background-color: #5bc0de;
  @apply font-bold px-1.5 rounded-sm ml-0.5 text-white #{!important};
}

mat-form-field.hide-subscript-wrapper .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.rendered-text {
  h1 {
    @apply text-2xl font-bold;
  }
  h2 {
    @apply text-xl font-bold;
  }
  h3 {
    @apply text-lg;
  }
  ul {
    @apply list-disc ml-6;
  }
  a {
    @apply text-primary;
  }
  a:hover {
    @apply underline cursor-pointer;
  }
  strong {
    @apply font-semibold;
  }
  em {
    @apply italic;
  }
}

.mat-drawer-backdrop {
  display: none !important;
}

.fuse-horizontal-navigation-menu-item .mdc-list-item__primary-text {
  width: 100%;
}

.mdc-button:active,
.mdc-button:focus {
  outline: 2px solid #027398 !important;
  outline-offset: 0px;
}

.dark {
  .mdc-button:active,
  .mdc-button:focus {
    outline: 2px solid #8bff76 !important;
  }
}

.round-icon-button {
  @apply flex justify-center items-center w-10 h-10 my-1 bg-primary rounded-full sm:my-0;

  &.btn-sm {
    @apply w-6 h-6;

    mat-icon {
      @apply w-4 h-4 min-w-4 max-w-4;
    }
  }


  &:active,
  &:focus &:hover {
    outline: 2px solid #027398 !important;
    outline-offset: 0px;
  }
  mat-icon {
    @apply text-black;
  }
}

.dark {
  .round-icon-button {
    &:active,
    &:focus &:hover {
      outline: 2px solid #8bff76 !important;
    }
  }
}

.mat-mdc-paginator {
  .mat-mdc-paginator-container {
    padding: 0 16px;
  }
}

.sbd-table.mat-mdc-table {
    width: 100%;
    table-layout: fixed !important;

    .mdc-data-table__header-row {
      height: 32px;
    }

    &.resizing {
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: col-resize;
    }

    .mat-mdc-cell {
      span.resize-holder {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .mat-mdc-header-cell {
      position: relative;

      &[ng-reflect-resizable="true"] {
        cursor: pointer;
      }

      &:not(:last-child) {
        .resize-holder {
          cursor: col-resize;
          width: 20px;
          height: 100%;
          position: absolute;
          right: -10px;
          top: 0;
          z-index: 1;
        }
      }
    }
    .mat-mdc-cell,
    .mat-mdc-header-cell {
      border-right: 1px solid var(--fuse-border) !important;
      // &:not(:nth-child(1)) {
      //   padding: 0 10px;
      // }

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 16px;
    }
}

.content-block {
  @apply m-4;
  h1 {
    @apply text-xl py-4 border-b border-b-primary font-bold text-primary;
  }
  h4 {
    @apply text-lg py-2 font-bold text-primary;
  }
  p {
    @apply py-2;
  }
}

.doug-table.mat-mdc-table {
  @apply w-full;

  @screen sm {
    // table-layout: fixed !important;
  }

  .mdc-data-table__header-row {
    @apply h-[32px];
  }

  .mat-mdc-cell {
    @apply leading-[18px];
  }
  // .mat-mdc-cell,
  // .mat-mdc-header-cell {
  //   @apply whitespace-nowrap overflow-hidden text-ellipsis leading-tight;
  // }
}

mat-radio-button {
  &.reversed-label{
    display: flex;
    .mdc-form-field {
      display: flex;
      width: 100%;

      .mdc-label {
        width: 100%;
        cursor: pointer;
      }
    }

  }
}

.cursor-not-allowed{
  cursor: not-allowed;
}

.initials-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
}

